import * as Sentry from '@sentry/nextjs'
import { createLogger } from './configuration/edge-logger'
import { Env } from './src/utils/env'

const isInspect = process.env.NODE_OPTIONS && process.env.NODE_OPTIONS.includes('inspect')
const logger = createLogger({ name: 'SentrySettings' })

type AnyObject = Record<string, unknown> | { [key: string]: any } | undefined
const pick = (object: AnyObject, fields: string[]) =>
  fields.reduce(
    (acc, field) => {
      if (object) {
        acc[field] = object[field]
      }
      return acc
    },
    {} as Record<string, unknown>,
  )

export const sentryCommonSettings: Sentry.BrowserOptions | Sentry.NodeOptions | Sentry.EdgeOptions = {
  enabled: Env.isProd() && !Env.isLocalhost(),

  dsn: 'https://26835edc56b205dcf5912e626113dd10@o4506475334991872.ingest.us.sentry.io/4506475339841536',
  // dsn: 'https://26835edc56b205dcf5912e626113dd10@o4506475334991872.ingest.sentry.io/4506475339841536',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Firefox extensions
    /^resource:\/\//i,
  ],
  allowUrls: [/dev-app\.mantisanalytics\.com/i, /app\.mantisanalytics\.com/i],

  ignoreErrors: [],

  beforeSendTransaction(event) {
    const header = event.sdkProcessingMetadata?.request?.headers || {}
    const isKeepAlive = header['connection'] === 'keep-alive'
    const isCookies = !!header['cookie']

    if (isKeepAlive || isCookies) {
      return event
    }

    if (isInspect) {
      return null
    }

    if (!Env.isProd()) {
      logger.debug(
        'Skipped event from quota',
        JSON.stringify({
          ...pick(event, ['transaction', 'type', 'transaction_info']),
          sdkProcessingMetadata: pick(event.sdkProcessingMetadata, ['source', 'request']),
        }),
      )
    }
    return null
  },
}

export const sentryOnInstrumentationInit = () => Sentry.init({ ...sentryCommonSettings })
